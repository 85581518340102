<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        Certificate
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card outlined>
                        <v-card-title class="title pb-0">
                            <v-flex xs5>
                                <v-select :loading="examLoading"
                                          :disabled="!exams.length"
                                          outlined dense
                                          :items="exams" class="pa-0" label="Exam"
                                          v-model="exam"/>
                            </v-flex>
                            <v-flex xs3 sm2>
                                <v-select :loading="gradeLoading" persistent-hint outlined dense
                                          :items="grades" class="pa-0" label="Grade" v-model="grade"
                                          :disabled="!exam || !grades.length || exam==='see'  || exam==='ble'"/>
                            </v-flex>
                            <v-flex xs3 sm2>
                                <v-select :items="sections" class="pa-0" label="Section" v-model="section" outlined
                                          dense
                                          :disabled="!grade"/>
                            </v-flex>
                        </v-card-title>
                        <v-fab-transition
                                v-if="isSelectedGradeMatched && form.items.data.length && showBulkPrint===true">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" style="position: absolute"
                                           color="blue"
                                           title="Print Bulk"
                                           fab
                                           dark
                                           small
                                           right
                                           bottom
                                           @click="print.enrollId='',print.sectionId=section,printCertificateBulk()">
                                        <v-icon>print</v-icon>
                                    </v-btn>
                                </template>
                                <span>Print all certificate.</span>
                            </v-tooltip>
                        </v-fab-transition>
                    </v-card>
                    <br>
                    <v-data-table
                            :headers="headers"
                            :items="form.items.data"
                            :loading="form.loading"
                            :options.sync="pagination"
                            :footer-props="footerProps"
                            class="marks_entry">
                        <template v-slot:item="{index, item}">
                            <tr :class="''">
                                <td class="text-xs-center">{{item.roll}}</td>
                                <td class="text-xs-left"><strong>{{item.name}}</strong></td>
                                <td class="text-xs-center">
                                    <table style="padding-top:10px;">
                                        <tr>
                                            <td style="padding-left: 0">
                                                <v-calendar-field
                                                        placeholder="YYYY-MM-DD"
                                                        :id-val="'start_date-'+index"
                                                        v-model="item.certificates.from"
                                                        label="From">
                                                </v-calendar-field>
                                            </td>
                                            <td style="padding-left: 0">
                                                <v-calendar-field
                                                        placeholder="YYYY-MM-DD"
                                                        :id-val="'end_date-'+index"
                                                        v-model="item.certificates.to"
                                                        label="To">
                                                </v-calendar-field>
                                            </td>
                                            <td style="padding-left: 0"
                                                v-if="isSelectedGradeMatched">
                                                <v-text-field v-model="item.gpa"
                                                              outlined dense
                                                              style="width: 100px;" autocomplete="off"
                                                              label="GPA Score"
                                                              required
                                                              class="pa-0"
                                                              name="name"/>
                                            </td>
                                            <td style="padding-left: 0">
                                                <v-autocomplete
                                                        v-model="item.certificates.moral_character"
                                                        style="width: 170px;"
                                                        :items="moralCharacters"
                                                        outlined dense
                                                        label="Character"
                                                ></v-autocomplete>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="padding-left: 0"
                                                v-if="isSelectedGradeMatched">
                                                <v-text-field v-model="item.certificates.reg_no"
                                                              outlined dense
                                                              style="" autocomplete="off"
                                                              label="Registration Number"
                                                              required
                                                              class="pa-0"
                                                              name="name"/>
                                            </td>

                                            <td colspan="2" style="padding-left: 0"
                                                v-if="isSelectedGradeMatched">
                                                <v-text-field v-model="item.certificates.sym_no"
                                                              outlined dense
                                                              style="" autocomplete="off"
                                                              label="Symbol Number"
                                                              required
                                                              class="pa-0"
                                                              name="name"/>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td class="text-right">
                                    <v-tooltip
                                            v-if="item.certificates.moral_character && item.certificates.to && item.certificates.from"
                                            bottom>
                                        <template v-slot:activator="{ on }">
                                            <!--<v-icon color="primary" dark v-on="on">home</v-icon>-->
                                            <v-btn v-on="on"
                                                   color="green"
                                                   title="Print single"
                                                   fab
                                                   dark
                                                   small
                                                   right
                                                   bottom
                                                   @click="print.onlySave=true,saveAndPrint(item)">
                                                <v-icon>save</v-icon>
                                            </v-btn>
                                        </template>

                                        <span>Save/Update for later</span>

                                    </v-tooltip>
                                    &nbsp;
                                    <v-tooltip bottom>
                                        <template
                                                v-if="item.certificates.moral_character && item.certificates.to && item.certificates.from"
                                                v-slot:activator="{ on }">
                                            <!--<v-icon color="primary" dark v-on="on">home</v-icon>-->
                                            <v-btn v-on="on"
                                                   color="blue"
                                                   title="Print single"
                                                   fab
                                                   dark
                                                   small
                                                   right
                                                   bottom
                                                   @click="print.onlySave=false,print.enrollId=item.enroll_id,print.sectionId='',saveAndPrintData(item)">
                                                <v-icon>print</v-icon>
                                            </v-btn>
                                        </template>

                                        <span>Save and print now.</span>

                                    </v-tooltip>

                                </td>
                            </tr>
                        </template>

                    </v-data-table>
                    <v-dialog v-model="issueDateDialog" persistent max-width="290px">
                        <v-card>
                            <v-card-title class="primary white--text">
                                <span class="title">Issue Date</span>
                            </v-card-title>
                            <v-card-text class="pb-1">
                                <v-text-field
                                        label="From Date" v-model="issueDate"
                                        prepend-icon="event"
                                        :mask="'####-##-##'"
                                        :rules="dateValidation"
                                        placeholder="YYYY-MM-DD"
                                        :height="25"
                                />
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="warning" outlined @click="issueDateDialog = false, printData = []">Close
                                </v-btn>
                                <v-btn color="success" outlined @click="saveAndPrint(printData)">Print
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import {validateDate} from "../../../../library/helpers";

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();
    import Mixins from '@/library/Mixins';

    export default {
        mixins: [Mixins],
        data: () => ({
            examLoading: false,
            gradeLoading: false,
            subjectLoading: false,
            form: new Form({
                enroll_id: '',
                name: '',
                roll: ''
            }, '/api/certificate'),
            students: [],
            pagination: {
                rowsPerPage: 100,
            },
            printData: [],
            issueDate: nd.format('YYYY-MM-DD'),
            issueDateDialog: false,
            moralCharacters: [
                'Outstanding',
                'Excellent',
                'Good',
                'Statisfactory',
                'Bad'
            ],
            rowsPerPageItems: [5, 10, 25, 50, 75, 100, 150],
            section: '',//'1026',
            sections: [],
            exam: '',
            exams: [],
            grade: '',
            grades: [],
            dateValidation: [
                (v) => validateDate(v) || 'Invalid Date Format',
            ],
            headers: [
                {text: 'Roll', align: 'center', value: 'roll', width: 50},
                {text: 'Name', align: 'left', value: 'name', sortable: false},
                {text: 'Certificate Records', align: 'left', value: 'theory', sortable: false},
                {text: 'Action', align: 'right', value: 'practical', width: 200, sortable: false},
            ],
            subject_marks_theory: 0,
            subject_marks_practical: 0,
            marks_entry: {},
            evaluation: '',
            showBulkPrint: false,
            print: {
                sectionId: '',
                enrollId: '',
                onlySave: true,
            }
        }),
        computed: {
            ...mapState(['batch']),

            isSelectedGradeMatched() {
                let output = false;
                let selectedGrade = null;
                let $this = this;
                this.grades.map((item) => {
                    if (item.value === $this.grade) {
                        selectedGrade = item;
                    }
                });

                // if (selectedGrade && /10|ten|grade ten|class 10|grade 10|class ten/i.test(selectedGrade.text)) {
                //     output = true;
                // }
                //
                // if (selectedGrade && /8|eight|grade eight|class 8|grade 8|class eight/i.test(selectedGrade.text)) {
                //     output = true;
                // }

                if (this.exam === 'see' || this.exam === 'ble') return true;
                return false;
            },
        },
        mounted() {
            this.getExams();
        },
        watch: {
            'batch': function (value) {
                this.get();
            },
            'grade': function () {
                this.section = '';
                this.subject = '';
                this.form.items.data = [];
                this.students = [];
                this.getSections();
            },
            'exam': function () {
                this.section = '';
                this.form.items.data = [];
                this.students = [];
                this.getGrades();
            },
            'subject': function () {
                // this.section = '';
                this.form.items.data = [];
                this.get();
            },
            'section': function () {
                this.get();
            },
            'pagination': function () {
                this.get()
            }
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&sectionId=' + this.section.toString();
            },
            updateDetails(value, i, type) {
                this.students[i].certificates[type] = value;
            },
            get(params) {
                if (this.section) {
                    let $this = this;
                    let query = [null, undefined].includes(params) ? this.queryString() : params;
                    this.form.get(null, query).then(({data}) => {
                        this.students = data.data.map(function (item) {
                            if (item.gpa) {
                                $this.showBulkPrint = true;
                            }
                            return item;
                        });
                    })
                }
            },
            saveAndPrintData(data) {
                this.printData = data;
                this.issueDateDialog = true;
            },
            saveAndPrint(singleStudent) {
                const {certificates} = singleStudent;
                // console.log(certificates, singleStudent, (this.isSelectedGradeMatched), certificates.from, certificates.to, certificates.moral_character);

                // || (this.isSelectedGradeMatched && !!singleStudent.gpa)
                if (!certificates.from || !certificates.to || !certificates.moral_character) {
                    this.$events.fire('notification', {message: "Invalid Data", status: 'error'})
                    return;
                }
                this.$rest.post('/api/certificate', {certificates: [singleStudent]}).then(({data}) => {
                    this.showBulkPrint = true;
                    if (!this.print.onlySave) {
                        let url = data.data.download_url + '?exam=' + this.exam + '&sectionId=' + this.section + '&enrollId=' + this.print.enrollId + '&issueDate=' + this.issueDate.dateForm();
                        window.open(url);
                        this.issueDateDialog = false;
                    } else {
                        this.$events.fire('notification', {message: "Certificate updated", status: 'success'})
                    }


                });
                // console.log(this.students[singleStudent]);
            },
            getGrades() {
                this.gradeLoading = true;
                let $this = this;
                this.$rest.get('/api/grades?rowsPerPage=25&descending=false&slim=true').then(({data}) => {
                    this.grade = '';

                    this.grades = data.data.map(item => {
                        if ($this.exam === 'see' && (item.name.toLowerCase() === 'class 10' || item.name.toLowerCase() === '10' || item.name.toLowerCase() === 'x' || item.name.toLowerCase() === 'grade 10' || item.name.toLowerCase() === 'ten')) {
                            $this.grade = item.id;

                        }
                        if ($this.exam === 'ble' && (item.name.toLowerCase() === 'class 8' || item.name.toLowerCase() === '8' || item.name.toLowerCase() === 'viii' || item.name.toLowerCase() === 'grade 8' || item.name.toLowerCase() === 'eight')) {
                            $this.grade = item.id;
                        }
                        return {value: item.id, text: item.name}
                    });
                }).finally(() => {
                    this.gradeLoading = false;
                })
            },
            getExams() {
                this.examLoading = true;
                this.$rest.get('/api/exam').then(({data}) => {
                    this.exams = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                    this.exams.push({value: 'see', text: 'Secondary Education Examination (SEE)'});
                    this.exams.push({value: 'ble', text: 'Basic Level Examination (BLE)'});
                }).finally(() => {
                    this.examLoading = false;
                })
            },
            getSections() {
                this.$rest.get('/api/section?batch=' + this.batch.id + '&grade=' + this.grade).then(({data}) => {
                    this.sections = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                })
            },
            printCertificateBulk() {
                this.$rest.get('/api/print/certificate').then(({data}) => {
                    let url = data.data.download_url + '?exam=' + this.exam + '&sectionId=' + this.section;
                    window.open(url)
                });

                console.log('print certificate bulk');
            }
        }
    }
</script>
<style lang="scss">
    .marks_entry {
        input[disabled] {
            background-color: #eee;
            color: #ccc !important;
            border-color: #ccc !important;
            cursor: not-allowed;
        }

        input[value='0'] {
            color: #ccc !important;
            border-color: #ccc !important;
        }

        .mark_entry_input {
            border: 1px solid #ccc;
            font-weight: bold;
            border-radius: 3px;
            width: 60px;
            height: 25px;
            text-align: center
        }

        .marks_entry_input_container {
            display: flex;
            align-items: center;
            justify-content: center;

            .v-input__slot {
                margin-bottom: 0 !important;
            }

            .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
                margin-bottom: 0 !important;
            }

            .v-input__control {
                display: -webkit-box !important;
                -webkit-box-orient: horizontal !important;
            }

            .v-input {
                -webkit-box-align: start;
                -ms-flex-align: start;
                align-items: flex-start;
                display: -webkit-box !important;
                display: -ms-flexbox;
                /* display: flex; */
                /* -webkit-box-flex: 1; */
                -ms-flex: 1 1 auto;
                flex: none;
                font-size: 16px;
                text-align: left;
            }
        }

        input:focus {
            outline: none;
        }

    }

    .v-input--selection-controls {
        margin-right: 20px !important;
        flex: none !important;
    }

</style>