export default {
    data: () => ({
        pagination: {
            itemsPerPage: 10,
            // descending: true
        },
        footerProps: {
            'items-per-page-options': [10, 25, 50, 100, -1]
        }
    }),
    methods: {
        queryString(extraParams = null) {
            let json = this.pagination;
            let j = JSON.parse(JSON.stringify(json, (k, v) => Array.isArray(v) && !(v = v.filter(e => e)).length ? void 0 : v, 2).replace("itemsPerPage", "rowsPerPage").replace('sortDesc', 'descending'));

            if (j.sortBy) {
                j.sortBy = j.sortBy[0];
                if (j.descending) {
                    j.descending = j.descending[0];
                } else {
                    j.descending = false;
                }
            }


            let paginatedData = Object.keys(j).map(key => {
                if (typeof j[key] === 'object') {
                    let vals = [...j[key]];
                    if (vals.length) return encodeURIComponent(key) + '=' + encodeURIComponent(vals.join(','));
                    else return null;
                } else if (!["", undefined].includes(j[key])) {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(j[key]);
                }
            }).filter(Boolean).join('&');

            if (extraParams) {
                paginatedData = `${paginatedData}&${extraParams}`;
            }

            return `?${paginatedData}`;
        }
    }
}